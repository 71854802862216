<script setup lang="ts">
import { type VariantProps, tv } from 'tailwind-variants';

export type badgeTypeVariants = VariantProps<typeof badgeVariants>

export interface BadgeProps {
  colour: badgeTypeVariants['colour']
  text: string
  variant: badgeTypeVariants['variant']
}

const props = defineProps<BadgeProps>();

const emit = defineEmits(['remove'])

const badgeVariants = tv({
  base: 'inline-flex items-center px-2 py-1 text-xs font-medium ring-1 ring-inset gap-x-0.5',
  variants: {
    colour: {
      primary: 'bg-primary-50 text-primary-700 ring-primary-700/20',
      purple: 'bg-purple-50 text-purple-700 ring-purple-700/10',
      red: 'bg-red-50 text-red-700 ring-red-600/10',
      yellow: 'bg-yellow-50 text-yellow-800 ring-yellow-600/20',
      green: 'bg-green-50 text-green-700 ring-green-600/20',
      indigo: 'bg-indigo-50 text-indigo-700 ring-indigo-600/10',
      pink: 'bg-pink-50 text-pink-700 ring-pink-700/10',
    },
    variant: {
      rounded: 'rounded-full',
      input: 'rounded-sm',
    },
  },
});

const iconVariants = tv({
  base: 'h-3.5 w-3.5',
  variants: {
    colour: {
      primary: 'stroke-primary-700/50 group-hover:stroke-primary-700/75',
      purple: 'stroke-purple-600/50 group-hover:stroke-purple-600/75',
      red: 'stroke-red-600/50 group-hover:stroke-red-600/75',
      yellow: 'stroke-yellow-700/50 group-hover:stroke-yellow-700/75',
      green: 'stroke-green-700/50 group-hover:stroke-green-700/75',
      indigo: 'stroke-indigo-600/50 group-hover:stroke-indigo-600/75',
      pink: 'stroke-pink-700/50 group-hover:stroke-pink-700/75',
    },
  },
})

const buttonVariants = tv({
  base: 'group relative -mr-1 h-3.5 w-3.5 rounded-sm',
  variants: {
    colour: {
      primary: 'hover:bg-primary-600/20',
      purple: 'hover:bg-purple-600/20',
      red: 'hover:bg-red-600/20',
      yellow: 'hover:bg-yellow-600/20',
      green: 'hover:bg-green-600/20',
      indigo: 'hover:bg-indigo-600/20',
      pink: 'hover:bg-pink-600/20',
    },
  },
})
</script>

<template>
  <span
    v-bind="$attrs"
    :class="badgeVariants({ colour, variant })"
  >
    {{ text }}
    <button v-if="props.variant === 'input'" :class="buttonVariants({ colour })" @click="emit('remove', props.text)">
      <span class="sr-only">Remove</span>
      <!-- iconify implementation does not work here, leaving as a one off since it's in a base component -->
      <svg viewBox="0 0 14 14" class="" :class="iconVariants({ colour })">
        <path d="M4 4l6 6m0-6l-6 6" />
      </svg>
      <span class="absolute -inset-1" />
    </button>
  </span>
</template>
