<script setup lang="ts" generic="T extends Record<PropertyKey, any>">
import { computed, ref } from 'vue';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';

interface Props {
  /** Whether the User can add new entries into this Combobox. Default: false */
  ableToAdd?: boolean
  /** Options of combobox */
  items: T[]
  /** Key property. Default: 'id' */
  keyProperty?: string
  /** Label Property. Default: 'name' */
  labelProperty?: string
  /** Whether multiple items can be selected. Default: false */
  multiple?: boolean
  /** Label of combobox. */
  label?: string
  /** Placeholder when none selected. Default: 'Search' */
  placeholder?: string
  /** Error message under combobox */
  errorMessage?: string
}

const props = withDefaults(defineProps<Props>(), {
  keyProperty: 'id',
  labelProperty: 'name',
  multiple: false,
  label: undefined,
  placeholder: 'Search',
  errorMessage: undefined,
  ableToAdd: false,
})

const emit = defineEmits<{
  remove: [item: T]
}>();

const modelValue = defineModel<T[] | T>({ required: true });

const query = ref('');
const filteredItems = computed(() =>
  props.items.filter((item) => {
    return item[props.labelProperty].toLowerCase().includes(query.value.toLowerCase());
  }),
);
const customItem = computed(() => {
  if (query.value === '') {
    return null
  }

  const out = {
    [props.keyProperty]: query.value,
    [props.labelProperty]: query.value,
  }
  return out;
})
const somethingSelected = computed(() => {
  if (Array.isArray(modelValue.value)) {
    return modelValue.value.length > 0;
  }
  else {
    return modelValue.value[props.keyProperty];
  }
});
function removeItem(item: T) {
  emit('remove', item);
}
function clearSelection() {
  modelValue.value = []
}
</script>

<template>
  <Combobox
    v-model="modelValue"
    as="div"
    :multiple="multiple"
  >
    <ComboboxLabel
      v-if="label"
      class="mb-1 block text-sm text-slate-700 font-medium leading-6"
    >
      {{ label }}
    </ComboboxLabel>
    <div class="relative">
      <ComboboxButton
        class="w-full border-0 rounded-md py-1.5 pl-1.5 pr-12 text-slate-900 shadow-sm ring-1 ring-slate-300 ring-inset sm:text-sm sm:leading-6 focus:ring-2 focus:ring-primary-600 focus:ring-inset" :class="[
          $attrs.disabled ? 'bg-neutral-100' : 'bg-white',
        ]"
      >
        <div
          v-if="multiple"
          class="flex gap-x-1 overflow-hidden"
        >
          <CTag
            v-for="item in (modelValue as T[])"
            :key="item[keyProperty]"
            class="shrink-0 items-center gap-x-0.5 rounded-sm px-2 py-1 text-xs font-medium" :class="[
              $attrs.disabled ? 'bg-neutral-200 text-neutral-600' : 'bg-primary-100 text-primary-700',
            ]"
            :tag="item[labelProperty]"
            :removeable="!$attrs.disabled"
            @remove="removeItem(item)"
          />
        </div>
        <span
          v-else
          class="flex overflow-hidden"
        >
          {{ (modelValue as T)[labelProperty] }}
        </span>
        <div
          v-if="!somethingSelected"
          class="inset-y-0 left-0 flex items-center rounded-r-md text-slate-400 focus:outline-none"
        >
          <i-material-symbols-search
            class="mr-1 h-5 w-5"
          />
          {{ props.placeholder }}
        </div>
        <div
          v-if="!$attrs.disabled"
          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          <button data-test="delete-button" @click.prevent="clearSelection">
            <i-heroicons:trash-20-solid
              v-if="somethingSelected && multiple"
              class="mr-1 h-4 w-4 text-slate-400 hover:text-slate-500"
            />
          </button>
          <span
            class="i-heroicons:chevron-up-down-20-solid h-5 w-5 text-slate-400"
            aria-hidden="true"
          />
        </div>
      </ComboboxButton>

      <ComboboxOptions
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
      >
        <ComboboxInput
          class="w-full rounded-md bg-white text-slate-900 focus:border-transparent sm:text-sm sm:leading-6 focus:outline-none focus:ring-transparent"
          :placeholder="props.placeholder"
          @change="query = $event.target.value"
        />

        <div
          v-if="filteredItems.length < 1 && query !== ''"
          class="relative cursor-default select-none px-4 py-2 text-gray-700"
        >
          Nothing found.
        </div>

        <ComboboxOption
          v-if="ableToAdd && customItem" v-slot="{ active }"
          :value="customItem"
        >
          <li class="relative cursor-default select-none py-2 pl-8 pr-4" :class="[active ? 'bg-primary-600 text-white' : 'text-slate-900']">
            Add "{{ query }}"
          </li>
        </ComboboxOption>

        <ComboboxOption
          v-for="item in filteredItems"
          :key="item[keyProperty]"
          v-slot="{ active, selected }"
          :value="item"
          as="template"
        >
          <li class="relative cursor-default select-none py-2 pl-8 pr-4" :class="[active ? 'bg-primary-600 text-white' : 'text-slate-900']">
            <span class="block truncate" :class="[selected && 'font-semibold']">
              {{ item[labelProperty] }}
            </span>

            <span
              v-if="selected"
              class="absolute inset-y-0 left-0 flex items-center pl-1.5" :class="[active ? 'text-white' : 'text-primary-600']"
            >
              <span
                class="i-heroicons:check-20-solid h-5 w-5"
                aria-hidden="true"
              />
            </span>
            <slot
              name="optionAction"
              :option-key="item[props.keyProperty]"
              :option-label="item[props.labelProperty]"
            />
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
    <p
      v-if="errorMessage"
      class="mt-2 text-sm text-red-600"
    >
      {{ errorMessage }}
    </p>
  </Combobox>
</template>
