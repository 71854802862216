<script setup lang="ts" generic="T extends Record<PropertyKey, any>">
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { defineModel, useSlots } from 'vue';

const props = withDefaults(defineProps<SelectProps>(), {
  keyProperty: 'id',
  labelProperty: 'name',
  placeholder: 'Select an option',
  rounded: false,
  roundedL: false,
});

const model = defineModel<T>({ default: {}, required: true })
const slots = useSlots()

interface SelectProps {
  options: T[]
  keyProperty?: string
  errorMessage?: string
  labelProperty?: string
  placeholder?: string
  rounded?: boolean
  roundedL?: boolean
}
</script>

<template>
  <Listbox
    v-model="model"
    as="div"
  >
    <ListboxLabel class="hidden text-sm text-slate-700 font-medium">
      Assigned to
    </ListboxLabel>
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default py-1.5 pl-3 pr-10 text-left text-slate-600 font-medium shadow-sm ring-1 ring-slate-300 ring-inset sm:text-sm placeholder:text-slate-400 sm:leading-6"
        :class="{
          'bg-neutral-100': $attrs.disabled,
          'bg-white focus:outline-none focus:ring-2 focus:ring-primary-400 cursor-pointer': !$attrs.disabled,
          ['rounded-md']: !rounded,
          ['rounded-l-full']: roundedL,
        }"
      >
        <span
          v-if="model[props.labelProperty]"
          class="block truncate"
        >
          {{ model[props.labelProperty] }}
          <slot name="customListOption" :option="model" />
          <slot
            name="selectedLabelTrailingElement"
            :option-key="model[props.keyProperty]"
          />
        </span>
        <span
          v-else-if="slots.customListOption && model[props.keyProperty]"
          class="block truncate"
        >
          <slot name="customListOption" :option="model" />
        </span>
        <span
          v-else
          class="block truncate text-slate-400"
        >{{ props.placeholder }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <i-heroicons-chevron-up-down-solid
            class="h-5 w-5 text-slate-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          v-if="options.length > 0"
          class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm focus:outline-none"
        >
          <ListboxOption
            v-for="option in options"
            :key="option[props.keyProperty]"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
          >
            <li class="relative cursor-pointer select-none py-2 pl-8 pr-4" :class="[active ? 'bg-primary-600' : 'text-slate-900']">
              <span class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal', { 'text-white': active }]">
                {{ option[props.labelProperty] }}
                <slot name="customListOption" :option="option" />
                <slot
                  name="labelTrailingElement"
                  :option-key="option[props.keyProperty]"
                  :active="active"
                />
              </span>
              <slot
                name="optionAction"
                :option-key="option[props.keyProperty]"
                :option-label="option[props.labelProperty]"
              >
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5" :class="[active ? 'text-white' : 'text-primary-400']"
                >
                  <span
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </span>
              </slot>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
  <p
    v-if="errorMessage"
    class="mt-2 text-sm text-red-600"
  >
    {{ errorMessage }}
  </p>
</template>
