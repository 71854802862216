<script setup lang="ts">
import { type VariantProps, tv } from 'tailwind-variants';

type ButtonVariants = VariantProps<typeof buttonVariants>

interface Props {
  /** Whether button is rounded like a pill. */
  pill?: boolean
  /** Button has outline. */
  outline?: boolean
  /** Transparent background and bold text. */
  text?: boolean
  /** Disabled */
  disabled?: boolean
  /** Possible Values: 'xs' | 'sm' | 'md'  | 'lg'  | 'xl' | 'w15' | 'w20' | 'icon' */
  size?: ButtonVariants['size']
  /** Possible Values: 'primary' | 'danger' | 'secondary' | 'soft' | 'yellow' | 'icon' | 'text' */
  buttonType?: ButtonVariants['buttonType']
  /** Icon at left side. Use class (eg i-heroicons:paper-airplane-solid). */
  leadingIcon?: string
  /** Icon at right side. Use class (eg. i-heroicons:paper-airplane-solid). */
  trailingIcon?: string
}

withDefaults(defineProps<Props>(), {
  pill: false,
  outline: false,
  text: false,
  disabled: false,
  size: 'md',
  buttonType: 'primary',
  leadingIcon: undefined,
  trailingIcon: undefined,
})

const buttonVariants = tv({
  base: 'inline-flex items-center font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-400 focus-visible:outline-offset-2 transition',
  variants: {
    buttonType: {
      primary: 'text-white bg-primary-400 hover:bg-primary-500 active:bg-primary-600',
      secondary: 'text-slate-600 bg-white ring-1 ring-inset ring-slate-300 hover:bg-gray-100 active:bg-gray-200',
      danger: 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700',
      soft: 'text-primary-600 bg-primary-50 hover:bg-primary-100 active:bg-primary-200',
      text: 'font-bold bg-transparent',
      yellow: '',
      icon: 'text-slate-600 bg-white ring-1 ring-inset ring-slate-300 hover:bg-gray-100 active:bg-gray-200 ',
    },
    size: {
      xs: 'px-2 py-1 text-xs rounded-md',
      sm: 'px-2.5 py-1 text-sm rounded-md',
      md: 'px-3 py-2 text-sm rounded-md',
      lg: 'px-4 py-2 text-base rounded-md',
      xl: 'px-5 py-2.5 text-base rounded-md',
      w15: 'px-15 py-2 text-base rounded-md',
      w20: 'px-20 py-2 text-base rounded-md',
      icon: 'rounded-md p-2',
    },
    outline: {
      true: 'ring-1 ring-inset',
    },
    pill: {
      true: 'rounded-full',
    },
    text: {
      true: 'font-bold',
    },
    disabled: {
      true: 'cursor-not-allowed',
    },
  },
  compoundVariants: [
    {
      outline: true,
      buttonType: 'primary',
      class: 'text-primary-400 bg-white hover:bg-primary-400 hover:text-white border-primary-400 active:border-primary-600 hover:ring-transparent',
    },
    {
      outline: true,
      buttonType: 'danger',
      class: 'text-red-500 bg-white hover:bg-red-500 hover:text-white ring-red-300 hover:ring-transparent',
    },
    {
      disabled: true,
      buttonType: 'primary',
      class: 'bg-neutral-400 hover:bg-neutral-400 active:bg-neutral-400',
    },
    {
      disabled: true,
      buttonType: 'danger',
      class: 'bg-neutral-400 hover:bg-neutral-400 active:bg-neutral-400',
    },
    {
      disabled: true,
      buttonType: 'soft',
      class: 'bg-neutral-100 text-neutral-400 hover:bg-neutral-100 active:bg-neutral-100',
    },
    {
      disabled: true,
      buttonType: 'secondary',
      class: 'bg-neutral-100 text-neutral-400 hover:bg-neutral-100 active:bg-neutral-100 border-neutral-300',
    },
    {
      disabled: true,
      outline: true,
      class: 'bg-neutral-100 text-neutral-400 hover:text-neutral-400 hover:hover:bg-neutral-100 active:bg-neutral-100 border-neutral-300 active:border-neutral-300',
    },
    // Text Colors
    {
      buttonType: 'primary',
      text: true,
      class: 'text-primary-600 bg-transparent hover:bg-primary-100 active:bg-primary-200',
    },
    {
      buttonType: 'yellow',
      text: true,
      class: 'text-yellow-700 hover:bg-yellow-500/10 active:bg-yellow-600/20',
    },
    {
      buttonType: 'danger',
      text: true,
      class: 'text-red-600 bg-transparent hover:bg-red-400/10 active:bg-red-600/20',
    },
    {
      disabled: true,
      text: true,
      class: 'text-neutral-400 hover:bg-transparent active:bg-neutral-100',
    },
  ],
});
</script>

<template>
  <button
    :class="buttonVariants({ pill, outline, size, disabled, buttonType, text })"
    :disabled="disabled"
    type="button"
  >
    <span
      v-if="leadingIcon"
      :class="leadingIcon"
      class="mr-2 h-5 w-5 opacity-70 -ml-0.5"
    />
    <slot />
    <span
      v-if="trailingIcon"
      :class="trailingIcon"
      class="ml-1 h-5 w-5 opacity-70 -mr-1"
    />
  </button>
</template>
