import { createRouter, createWebHistory } from 'vue-router';
import isEmpty from 'lodash/isEmpty';
import devRoutes from '@/router/dev/index.js';
import EmailsContainer from '@/views/Emails/EmailsContainer.vue';
import MMCContainer from '@/views/Emails/MMCContainer.vue';
import SmsCampaignsContainer from '@/views/SmsCampaigns/SmsCampaignsContainer.vue';
import { useUserStore } from '@/stores/userStore';

const EmailsIndex = () => import('@/views/Emails/EmailsIndex.vue');
const TemplatesIndex = () => import('@/views/Emails/TemplatesIndex.vue');
const ScheduledIndex = () => import('@/views/Emails/ScheduledIndex.vue');
const SentEmailsIndex = () => import('@/views/Emails/SentEmailsIndex.vue');
const SmsCampaignsIndex = () => import('@/views/SmsCampaigns/SmsCampaignsIndex.vue');
const SmsCampaignsNew = () => import('@/views/SmsCampaigns/SmsCampaignsNew.vue');
const EventsIndex = () => import('@/views/Event/EventsIndex.vue');
const SurveysIndex = () => import('@/views/Surveys/SurveysIndex.vue');
const SurveyInfo = () => import('@/views/Surveys/SurveyInfo.vue');
const ReportIndex = () => import('@/views/Reports/ReportIndex.vue');
const SavedReportIndex = () => import('@/views/Reports/SavedReportIndex.vue');
const SavedReportShow = () => import('@/views/Reports/SavedReportShow.vue');
const EventView = () => import('@/views/Event/EventView.vue');
const UserProfile = () => import('@/views/Users/UserProfile.vue');
const UserManagement = () => import('@/views/Users/UserManagement.vue');
const TeamManagement = () => import('@/views/Users/TeamManagement.vue');
const UserIntegration = () => import('@/views/Users/UserIntegration.vue');
const UserEmailManagement = () => import('@/views/Users/UserEmailManagement.vue');
const UserCustomBranding = () => import('@/views/Users/UserCustomBranding.vue');
const GmailDashboardIndex = () => import('@/views/Emails/GmailDashboardIndex.vue');
const OutlookDashboardIndex = () => import('@/views/Emails/OutlookDashboardIndex.vue');
const FileManagerIndex = () => import('@/views/FileManager/FileManagerIndex.vue');
const UserSmsManagement = () => import('@/views/Users/UserSmsManagement.vue')

const baseURL = import.meta.env.VITE_API_BASE_URL;

const homeRoute = {
  path: '/',
  name: 'home',
  beforeEnter: () => {
    window.location.href = `${baseURL}/stats/campaigns`;
  },
};

const beforeEnterUserSetting = async (to, _, next) => {
  const userStore = useUserStore();
  if (isEmpty(userStore.currentUser)) {
    await userStore.fetchUser();
  }
  if (!userStore.dualUser && !userStore.mailUser && !userStore.smsUser) {
    next({
      name: 'FeatureDisabled',
      params: { orgId: to.params.orgId },
    });
  }
  else {
    next();
  }
};

let routes = [
  { path: '/teams/:orgId/emails/:mmc_id/best_performing_links', name: 'BestPerformingLinksShow', component: () => import('@/views/Emails/BestPerformingLinksShow.vue') },
  { path: '/teams/:orgId/saved_reports/:id', name: 'SavedReportShow', component: SavedReportShow },
  { path: '/teams/:orgId/saved_reports', name: 'SavedReportIndex', component: SavedReportIndex },
  { path: '/teams/:orgId/reports', name: 'ReportIndex', component: ReportIndex },
  { path: '/teams/:orgId/org_api_token', name: 'OrgApiTokenShow', component: () => import('@/views/ShowOrgApiToken.vue') },
  { path: '/teams/:orgId/gmail_dashboard', name: 'GmailDashboardIndex', component: GmailDashboardIndex },
  { path: '/teams/:orgId/outlook_dashboard', name: 'OutlookDashboardIndex', component: OutlookDashboardIndex },
  { path: '/teams/:orgId/contacts', name: 'ContactsIndex', component: () => import('@/views/Contacts/ContactsIndex.vue') },
  { path: '/teams/:orgId/sources', name: 'SourceIndex', component: () => import('@/views/Contacts/SourceIndex.vue') },
  { path: '/teams/:orgId/sources/new_csv', name: 'NewCsvSource', component: () => import('@/views/Contacts/NewCsvSource.vue') },
  { path: '/teams/:orgId/sources/new_azure', name: 'NewAzureSource', component: () => import('@/views/Contacts/NewAzureSource.vue') },
  { path: '/teams/:orgId/custom_lists', name: 'CustomListIndex', component: () => import('@/views/Contacts/CustomListIndex.vue') },
  { path: '/teams/:orgId/custom_lists/:id', name: 'CustomListShow', component: () => import('@/views/Contacts/CustomListShow.vue') }, // Because axios will not send the csrf-token as a header unless '/teams/' is part of the URL.
  { path: '/teams/:orgId/file_manager', name: 'FileManagerIndex', component: FileManagerIndex },

  {
    path: '/teams/:orgId/emails/:mmc_id',
    component: MMCContainer,
    children: [
      { path: 'clickmap', name: 'ClickmapShow', component: () => import('@/views/Emails/ClickmapShow.vue') },
      { path: 'surveys', name: 'EmailsSurveys', component: () => import('@/views/Emails/EmailsSurveys.vue') },
      { path: 'social-channels', name: 'SocialChannelsIndex', component: () => import('@/views/Emails/SocialChannels/SocialChannelsIndex.vue') },
    ],
  },

  {
    path: '/teams/:orgId',
    component: EmailsContainer,
    beforeEnter: async (to, _, next) => {
      const userStore = useUserStore();
      if (isEmpty(userStore.currentUser)) {
        await userStore.fetchUser();
      }
      if (!userStore.dualUser && !userStore.mailUser && !userStore.smsUser) {
        next({
          name: 'FeatureDisabled',
          params: { orgId: to.params.orgId },
        });
      }
      else if (userStore.smsUser) {
        next({
          name: 'FeatureDisabled',
          params: { orgId: to.params.orgId },
          query: { a: 'email' },
        });
      }
      else {
        next();
      }
    },
    children: [
      {
        path: 'templates',
        name: 'EmailsIndex',
        component: EmailsIndex,
        meta: {
          title: 'Email Library',
        },
      },
      {
        path: 'templates/my_templates',
        name: 'TemplatesIndex',
        component: TemplatesIndex,
        meta: {
          title: 'My Templates',
        },
      },
      {
        path: 'templates/sents',
        name: 'SentEmailsIndex',
        component: SentEmailsIndex,
        meta: {
          title: 'Sent Emails',
        },
      },
      {
        path: '/teams/:orgId/scheduled_sends',
        name: 'ScheduledIndex',
        component: ScheduledIndex,
        meta: {
          title: 'Scheduled Emails',
        },
      },
    ],
  },
  {
    path: '/teams/:orgId/events',
    name: 'EventsIndex',
    component: EventsIndex,
    meta: {
      title: 'Events',
    },
  },
  {
    path: '/teams/:orgId/surveys',
    name: 'SurveysIndex',
    component: SurveysIndex,
    meta: {
      title: 'Surveys',
    },
  },
  {
    path: '/teams/:orgId/surveys/:surveyId',
    name: 'SurveyInfo',
    component: SurveyInfo,
    meta: {
      title: 'Survey Info',
    },
  },
  {
    path: '/teams/:orgId/events/:eventId',
    name: 'EventView',
    component: EventView,
  },
  {
    path: '/teams/:orgId/stats/sms_campaigns',
    name: 'SmsCampaigns',
    component: SmsCampaignsContainer,
    beforeEnter: async (to, _, next) => {
      const userStore = useUserStore();
      if (isEmpty(userStore.currentUser)) {
        await userStore.fetchUser();
      }
      if (!userStore.dualUser && !userStore.mailUser && !userStore.smsUser) {
        next({
          name: 'FeatureDisabled',
          params: { orgId: to.params.orgId },
        });
      }
      else if (userStore.mailUser && !userStore.smsTrialUser) {
        next({
          name: 'FeatureDisabled',
          params: { orgId: to.params.orgId },
          query: { a: 'sms' },
        });
      }
      else {
        next();
      }
    },
    children: [
      {
        path: 'dashboard',
        name: 'SmsCampaignsIndex',
        component: SmsCampaignsIndex,
      },
      {
        path: 'compose',
        name: 'SmsCampaignsNew',
        component: SmsCampaignsNew,
      },
      {
        props: { sms: true },
        path: 'contacts',
        name: 'SmsContacts',
        component: () => import('@/views/Contacts/ContactsIndex.vue'),
      },
      {
        props: { sms: true },
        path: 'custom_lists',
        name: 'SmsCustomLists',
        component: () => import('@/views/Contacts/CustomListIndex.vue'),
      },
      {
        props: { sms: true },
        path: 'custom_lists/:id',
        name: 'SmsCustomListShow',
        component: () => import('@/views/Contacts/CustomListShow.vue'),
      },
      {
        props: { sms: true },
        path: 'sources',
        name: 'SmsSources',
        component: () => import('@/views/Contacts/SourceIndex.vue'),
      },
      {
        props: { sms: true },
        path: 'new_azure_source',
        name: 'SmsNewAzureSource',
        component: () => import('@/views/Contacts/NewAzureSource.vue'),
      },
      {
        props: { sms: true },
        path: 'new_csv_source',
        name: 'SmsNewCsvSource',
        component: () => import('@/views/Contacts/NewCsvSource.vue'),
      },
    ],
  },
  {
    path: '/teams/:orgId/users/settings/profile',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: beforeEnterUserSetting,
  },
  {
    path: '/teams/:orgId/users/settings/user_management/users',
    name: 'UserManagement',
    component: UserManagement,
    beforeEnter: beforeEnterUserSetting,
  },
  {
    path: '/teams/:orgId/users/settings/user_management/teams',
    name: 'TeamManagement',
    component: TeamManagement,
    beforeEnter: beforeEnterUserSetting,
  },
  {
    path: '/teams/:orgId/users/settings/integration',
    name: 'UserIntegration',
    component: UserIntegration,
    beforeEnter: beforeEnterUserSetting,
  },
  {
    path: '/teams/:orgId/users/settings/email_management',
    name: 'UserEmailManagement',
    component: UserEmailManagement,
    beforeEnter: beforeEnterUserSetting,
  },
  {
    path: '/teams/:orgId/users/settings/custom_branding',
    name: 'UserCustomBranding',
    component: UserCustomBranding,
    beforeEnter: beforeEnterUserSetting,
  },
  {
    path: '/teams/:orgId/users/settings/sms_management',
    name: 'UserSmsManagement',
    component: UserSmsManagement,
    beforeEnter: beforeEnterUserSetting,
  },
  { path: '/teams/:orgId/events/new', name: 'EventCreate', component: () => import('@/views/Event/EventCreate.vue') },
  { path: '/teams/:orgId/events/:eventId/edit', name: 'EventUpdate', component: () => import('@/views/Event/EventUpdate.vue') },
  { path: '/teams/:orgId/account_feature_disabled', name: 'FeatureDisabled', component: () => import('@/views/FeatureDisabled.vue') },
  { path: '/teams/:orgId/account_deactivated', name: 'Deactivated', component: () => import('@/views/CmDeactivated.vue') },
  { path: '/teams/:orgId/account_expired', name: 'Expired', component: () => import('@/views/CmExpired.vue') },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    beforeEnter: async (to, from, next) => {
      const userStore = useUserStore();
      if (isEmpty(userStore.currentUser)) {
        await userStore.fetchUser();
      }

      // Update the orgId parameter directly
      to.params.orgId = userStore?.currentUser?.organization_team?.id;
      next();
    },
  },
];

if (process.env.NODE_ENV === 'development') {
  routes = [...devRoutes, ...routes];
}

// only has the redirect to the home route in production
// enable before prod deploy this makes it currently impossible to go to emails on staging
if (process.env.NODE_ENV === 'production') {
  routes = [homeRoute, ...routes];
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to) => {
  const userStore = useUserStore();
  userStore.updateCurrentTeam(Number.parseInt(to?.params?.orgId) || 0);
});

export default router;
