<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

export interface Props {
  /** The title of slideover */
  title?: string
  /** Description under title */
  description?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  description: '',
});

const emit = defineEmits<{
  submit: [e: Event]
}>();
const open = defineModel<boolean>('open', { default: false })
</script>

<template>
  <TransitionRoot
    as="template"
    :show="open"
  >
    <Dialog
      as="div"
      class="relative z-10"
      @close="open = false"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-slate-900 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 max-w-full flex pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-300"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto max-w-2xl w-screen">
                <!-- @vue-expect-error -->
                <form
                  class="h-full flex flex-col overflow-y-auto bg-white shadow-xl"
                  @submit.prevent="emit('submit', event)"
                >
                  <div class="flex-1">
                    <!-- Header -->
                    <div id="dialogTitle" class="border-b border-slate-200 bg-slate-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="flex-1 space-y-1">
                          <DialogTitle class="text-lg text-slate-800 font-bold leading-6">
                            {{ props.title }}
                          </DialogTitle>
                          <p
                            v-if="description"
                            class="text-sm text-slate-500"
                          >
                            {{ props.description }}
                          </p>
                          <slot name="description" />
                        </div>
                        <div class="h-7 flex items-center">
                          <button
                            type="button"
                            class="relative rounded text-slate-400 hover:bg-slate-200 hover:text-slate-500"
                            @click="open = false"
                          >
                            <!-- <span class="absolute -inset-2.5" /> -->
                            <span class="sr-only">Close panel</span>
                            <i-heroicons-x-mark-20-solid
                              class="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-slate-200">
                      <!-- Project name -->
                      <slot />
                    </div>
                  </div>
                  <!-- Action buttons -->
                  <div
                    v-if="$slots.footer"
                    class="sticky bottom-0 flex-shrink-0 border-t border-slate-200 bg-slate-50 px-4 py-5 sm:px-6"
                  >
                    <div class="flex justify-between space-x-3">
                      <slot name="footer" />
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
