import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useHeadTitle() {
  const routeInfo = useRoute();

  return computed(() => {
    if (/\/new\/team_templates/.test(routeInfo.path)) {
      return 'ContactMonkey | Create';
    }
    else if (/\/my_templates/.test(routeInfo.path)) {
      return 'ContactMonkey | Templates';
    }
    else if (/\/templates/.test(routeInfo.path)) {
      return 'ContactMonkey | Emails';
    }
    else if (/scheduled_sends/.test(routeInfo.path)) {
      return 'ContactMonkey | Scheduled Emails';
    }
    else if (/\/gmail_dashboard/.test(routeInfo.path)) {
      return 'ContactMonkey | Gmail Sending';
    }
    else if (/\/outlook_dashboard/.test(routeInfo.path)) {
      return 'ContactMonkey | Outlook Sending';
    }
    else if (/\/contacts|\/sources|\/custom_lists/.test(routeInfo.path)) {
      return 'ContactMonkey | Contacts';
    }
    else if (/\/events/.test(routeInfo.path)) {
      return 'ContactMonkey | Events';
    }
    else if (/\/account_deactivated/.test(routeInfo.path)) {
      return 'ContactMonkey | Account Deactivated';
    }
    else if (/\/account_expired/.test(routeInfo.path)) {
      return 'ContactMonkey | Account Expired';
    }
    else if (/feature_disabled/.test(routeInfo.path)) {
      return 'ContactMonkey | Feature Disabled';
    }
    else if (/\/sms_campaigns\/dashboard/.test(routeInfo.path)) {
      return 'ContactMonkey | SMS Analytics';
    }
    else if (/\/sms_campaigns\/compose/.test(routeInfo.path)) {
      return 'ContactMonkey | SMS Compose & Send';
    }
    else if (/\/sms_campaigns\/directories/.test(routeInfo.path)) {
      return 'Contactmonkey | SMS Recipients';
    }
    else if (/\/sms_campaigns\/lists/.test(routeInfo.path)) {
      return 'Contactmonkey | SMS Recipient Lists';
    }
    else if (/\/users/.test(routeInfo.path)) {
      return 'ContactMonkey | User Profile';
    }
    else if (/reports/.test(routeInfo.path)) {
      return 'ContactMonkey | Reports';
    }
    else if (/clickmap/.test(routeInfo.path)) {
      return 'ContactMonkey | Click Map';
    }
    else if (/social-channels/.test(routeInfo.path)) {
      return 'ContactMonkey | Social Channels';
    }
    else if (/best_performing_links/.test(routeInfo.path)) {
      return 'ContactMonkey | Best Performing Links';
    }
    else if (/file_manager/.test(routeInfo.path)) {
      return 'ContactMonkey | File Manager';
    }
    else if (/surveys/.test(routeInfo.path)) {
      return 'ContactMonkey | Surveys';
    }
    else {
      return 'ContactMonkey | 404';
    }
  });
}
